import { InvestigationStatus } from 'services/cyber-api';

export class InvestigationStatusIconValueConverter {
    private toView(status: InvestigationStatus): string {
        switch (status) {
            case InvestigationStatus.Requested: return 'fal fa-paper-plane';
            case InvestigationStatus.Inprogress: return 'fal fa-clock';
            case InvestigationStatus.Completed: return 'fal fa-file-pdf';
            case InvestigationStatus.Cancelled: return 'fal fa-ban';
        }
    }
}
