export class FileExtensionToIconValueConverter {
    public toView(filename: string) {
        const extension = filename.split('.').pop();
        const base_path = 'assets/images/icons/files/';
        switch (extension) {
            case 'png':
                return `${base_path}png.svg`;
            case 'jpeg':
            case 'jpg':
                return `${base_path}jpg.svg`;
            case 'ai':
                return `${base_path}ai.svg`;
            case 'avi':
                return `${base_path}avi.svg`;
            case 'css':
                return `${base_path}css.svg`;
            case 'csv':
                return `${base_path}csv.svg`;
            case 'dbf':
                return `${base_path}dbf.svg`;
            case 'dwg':
                return `${base_path}dwg.svg`;
            case 'exe':
                return `${base_path}exe.svg`;
            case 'fla':
                return `${base_path}fla.svg`;
            case 'html':
                return `${base_path}html.svg`;
            case 'iso':
                return `${base_path}iso.svg`;
            case 'js':
                return `${base_path}js.svg`;
            case 'json':
                return `${base_path}json.svg`;
            case 'mp3':
                return `${base_path}mp3.svg`;
            case 'mp4':
                return `${base_path}mp4.svg`;
            case 'pdf':
                return `${base_path}pdf.svg`;
            case 'doc':
            case 'docx':
                return `${base_path}doc.svg`;
            case 'ppt':
            case 'pptx':
                return `${base_path}ppt.svg`;
            case 'psd':
                return `${base_path}psd.svg`;
            case 'rtf':
                return `${base_path}rtf.svg`;
            case 'svg':
                return `${base_path}svg.svg`;
            case 'txt':
                return `${base_path}txt.svg`;
            case 'xls':
            case 'xlsx':
                return `${base_path}xls.svg`;
            case 'xml':
                return `${base_path}xml.svg`;
            case 'zip':
                return `${base_path}zip.svg`;
            default: return `${base_path}file.svg`;
        }
    }
}
