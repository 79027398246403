export class MaxLengthValueConverter {
    private toView(text: string, maxLength: number = 5, suffix: string = ''): string {
        if (!text) return text;

        let formatted = text;

        if (maxLength > 0 && text.length > maxLength) {
            formatted = text.substr(0, maxLength);
            if (suffix) formatted += suffix;
        }

        return formatted;
    }
}
