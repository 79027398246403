export class SolutionLinkValueConverter {
    /**
     * Converts a target value to a VAS link based on whether the target is an IP address or a domain name.
     * @param target A target value, either an IP address or a domain name
     */
    private toView(target: string): string {
        if (!target) return target;

        // To check whether the target is an IP address or a domain name, we can remove the periods from the target and
        // check if the rest of it is a valid number or not. A valid number means the target is an IP address, otherwise
        // its a domain name.
        const targetWithoutPeriods = target.split('.').join('');
        const targetAsNumber = parseInt(targetWithoutPeriods);
        const isDomainName = isNaN(targetAsNumber);

        return isDomainName
            ? 'datamanager.marlink.com'
            : 'portal360.com';
    }
}
