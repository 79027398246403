export class ThreatStatusColorValueConverter {
    private toView(name: string): string {
        switch (name.toLowerCase()) {
            case 'closed':
                return 'green';
            case 'pending':
                return 'orange';
            case 'ongoing':
                return 'yellow';
            case 'rejected':
            default:
                return 'grey-light';
        }
    }
}
