import moment from 'moment';

export class TimeAgoValueConverter {
    private toView(value: moment.MomentInput): string {
        if (!value) return null;

        // When value is a moment, we can immediately call .fromNow()
        if (moment.isMoment(value))
            return value.fromNow();

        // When value is a Date, convert it into a moment first
        if (value instanceof Date)
            return moment(value).fromNow();

        // When value is a string, first parse it into a Date as it's more efficient, then to a moment
        if (typeof value === 'string')
            return moment(Date.parse(value)).fromNow();

        // Value can only be a number by now, convert it to a moment
        if (typeof value === 'number') {
            // When value has a length of 10, multiply it by 1000 to get the timestamp in milliseconds
            if (value.toString().length === 10)
                value = (value as number) * 1000;

            return moment(value).fromNow();
        }

        // In all other cases, return null as we don't know what to do with the value
        return null;
    }
}
