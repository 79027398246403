import { ICollapsible } from 'models/icollapsible';

export class AreAllCollapsedValueConverter {
    private toView(values: ICollapsible[]): boolean {
        if (!values) return false;

        const areAllCollapsed = values.every((x) => x.collapsed);

        return areAllCollapsed;
    }
}
