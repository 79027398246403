import { IThreatXChangeDetailsUser } from '../utilities/x-change-utilities';

/**
 * Converts a list of XChange usernames to a string with breaks between the usernames, meant for a title attribute
 */
export class XChangeUsernamesTitleValueConverter {
    private toView(users: IThreatXChangeDetailsUser[]): string {
        let returnValue = '';

        for (const user of users) {
            // Add a break if there is already a return value, adding the next username to the next line
            if (returnValue) returnValue = `${returnValue}\n`;

            returnValue += `Username: ${user.username}`;
        }

        return returnValue;
    }
}
