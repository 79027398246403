import { IThreatXChangeDetailsMachine } from 'utilities/x-change-utilities';

/**
 * Converts a list of XChange machines to a string, meant for a title attribute
 */
export class XChangeMachinesTitleValueConverter {
    private toView(machines: IThreatXChangeDetailsMachine[]): string {
        let returnValue = '';

        for (const machine of machines) {
            // Add a break if there is already a return value, adding the next machine's name to the next line
            if (returnValue) returnValue = `${returnValue}\n`;

            returnValue += `Machine Name: ${machine.machineName || 'Unknown'}`;
        }

        return returnValue;
    }
}
