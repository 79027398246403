export class PercentageColorValueConverter {
    private toView(value: number): string {
        if (value >= 66)
            return 'red';

        if (value >= 33)
            return 'orange';

        if (value > 0)
            return 'yellow';

        if (value === 0 || value === null)
            return 'green';
    }
}
