import * as moment from 'moment';

export class DurationValueConverter {
    private toView(input: string): string {
        if (!input) return '-';

        if (!input.toUpperCase().startsWith('P'))
            // Exception: return the older alerts (pre < ~01/04/2019) simply as-is. See devops#2275 for details.
            return input;

        // Parse it as 'moment.Duration' object
        const duration = moment.duration(input); // ex. 'P1DT6H30M'

        // Display it in humanly-readable format
        const hum = duration.humanize();

        return duration.humanize();
    }
}
