import { IThreatXChangeDetailsMachine } from 'utilities/x-change-utilities';

/**
 * Converts a list of XChange mac addresses to a string, meant for a title attribute
 */
export class XChangeMacAddressesTitleValueConverter {
    private toView(machines: IThreatXChangeDetailsMachine[]): string {
        let returnValue = '';

        for (const machine of machines) {
            // Add a break if there is already a return value, adding the next machine's MAC address to the next line
            if (returnValue) returnValue = `${returnValue}\n`;

            returnValue += `MAC Address: ${machine.macAddress || 'Unknown'}`;
        }

        return returnValue;
    }
}
