export class CounterMeasureActionStatusValueConverter {
    private toView(status: string): string {
        switch (status) {
            case 'activated':
                return 'reported';
            case 'denied':
                return 'ignored';
            case 'created':
                return 'created';
            default:
                return status;
        }
    }
}
