import { SicAlert, AlertDetailSchemaActionSteps } from 'services/cyber-api';
import { ActionStepsUtilities } from 'utilities/action-steps-utilities';

export class ActionStepsTargetValueConverter {
    private toView(steps: AlertDetailSchemaActionSteps[], isXChange: boolean = false, threat: SicAlert = null): string {
        const target = ActionStepsUtilities.getTarget(steps);

        // When vessel uses XChange and resolved target is the same as the threat's source, the target is actually the
        // XChange box's IP. We don't want to show this as the target, so return an empty target string.
        // For details, see https://dev.azure.com/marlink/Marlink%20Digitalization/_workitems/edit/3463
        if (isXChange && threat && target === threat.source)
            return null;

        return ActionStepsUtilities.getTargetDisplay(target);
    }
}
