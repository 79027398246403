export class NotificationDeliveryTargetValueConverter {
    private toView(deliveryTarget: string): string {
        if (!deliveryTarget) return deliveryTarget;

        const target = deliveryTarget.toLowerCase();

        if (target.includes('web'))
            return '<i class="fal fa-chart-pie fa-fw"></i> Cyber Detection Portal';

        if (target.includes('mail')) {
            const email = target.split(':')[1];
            return email
                ? `<i class="fal fa-envelope fa-fw"></i> ${email}`
                : '<span class="text-danger"><i class="fas fa-exclamation fa-fw"></i> Email not configured</span>'; // This should not occur in the future, but just in case
        }

        return null;
    }
}
