export class PercentageValueConverter {
    private toView(percentage: number, precision: number = 0, round: boolean = true): string {
        // Validate the percentage as a usable number
        if (
            percentage === null
            || percentage === undefined
            || isNaN(percentage)
        ) return '-';

        if (!round) {
            // To counter rounding of the .toFixed implementation, we floor the numbers/decimals that come after the
            // defined precision.
            const factor = Math.pow(10, precision);
            percentage = Math.floor(percentage * factor) / factor;
        }

        return percentage.toFixed(precision);
    }
}
