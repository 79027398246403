export class ThreatCategoryTooltipValueConverter {
    private toView(category: string): string {
        let tooltipText: string;

        switch (category) {
            case 'abusive-content':
                tooltipText = 'This threat category means a vessel has received harassing or malicious content (e.g. spam, phishing) or content which should have been blocked according to your IT Policy';
                break;

            case 'abusive-usage':
                tooltipText = 'This threat category means a vessel bypassed your IT Policy and accessed a web domain of a blocked category or a prohibited application (e.g. Gambling, P2P)';
                break;

            case 'availability':
                tooltipText = 'A system is under attack with an exhaustion of its resources, e.g. a Denial of Service (DoS) attack to force a system outage by flooding it with requests creating resources overload';
                break;

            case 'fraud':
                tooltipText = 'Fraud attacks use a fake identity, such as a network or company identity, to gain unauthorized access to system privileges and sensitive information';
                break;

            case 'information-content-security':
                tooltipText = 'Intentional access and/or modification of information that was not supposed to be known/owned by the cyber attacker';
                break;

            case 'information-gathering':
                tooltipText = 'Before launching a cyber attack, a threat actor tries to gain as much information about the network as possible (e.g. Social Engineering, portscan, appscan, sweepscan)';
                break;

            case 'intrusion-attempt':
            case 'intrusion-attempts':
                tooltipText = 'An intrusion attempt aims to illegitimately enter either a computer, system or network to access and/or manipulate information or render a system unreliable or unusable';
                break;

            case 'intrusion':
            case 'intrusions':
                tooltipText = 'A successful compromission of a system or application (service). This can have been caused by a known or new vulnerability, but also by an unauthorized local access';
                break;

            case 'malicious-code':
                tooltipText = 'A computer onboard a vessel is communicating with an IP address associated to malware and is therefore very likely infected';
                break;

            case 'malicious-traffic':
                tooltipText = 'Malicious traffic sent by Malicious Code over your network may exfiltrate data, send commands to infected machines and redirect to malicious sites';
                break;

            case 'other':
                tooltipText = 'Any malicious activity which does not fit into one of the other categories (e.g. customer-specific rules or public blacklists)';
                break;

            case 'policy-violation':
                tooltipText = 'This alert type is raised if a vessel has accessed content violating your Corporate IT Policy, as defined in your Cyber Detection Profile';
                break;

            case 'vulnerable':
                tooltipText = 'Technical vulnerabilities can be resulting from errors in design, configuration, or the code itself and should be fixed to avoid further attacks (e.g. patch deployment)';
                break;

            default:
                tooltipText = null;
                break;
        }

        if (tooltipText)
            tooltipText += '\n\nClick to find out more';

        return tooltipText;
    }
}
