import { autoinject } from 'aurelia-framework';
import { StateApi } from 'services/state-api';

@autoinject()
export class IdToCompanyValueConverter {
    constructor(private state: StateApi) { }

    private toView(id: string): string {
        if (!id) return id;

        const companies = this.state.companies();

        if (companies) {
            const company = companies.find((c) => c.id.toUpperCase() === id.toUpperCase());

            return company ? company.name : null;
        }
    }
}
