export class ThreatStatusNameValueConverter {
    private toView(value: string, handled?: boolean): string {
        if (!value) return value;

        // As we use different display names for the statuses we are supplied with, convert them
        switch (value.toLowerCase()) {
            case 'ongoing':
                return handled ? 'Handled' : 'Active';
            case 'rejected':
                return 'Ignored';
            case 'closed':
                return 'Closed';
            default:
                return 'Unknown';
        }
    }
}
