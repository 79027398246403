export class LastActionValueConverter {
    private toView(action: string, channel: string): string {
        let actionName = action;
        switch (action) {
            // you only need to specify the special cases here
            case 'block':
                actionName = 'blocked';
                break;
            case 'pass':
                actionName = 'passthrough';
                break;
            case 'drop':
                actionName = 'dropped';
                break;
        }

        switch (channel) {
            case 'Network Threat':
                return `${actionName} by SIC`;
            case 'Universal Threat Management':
                return `${actionName} by UTM`;
            case 'Endpoint Detection and Response':
                return `${actionName} by EDR`;
        }
    }
}
