export class TruncateValueConverter {
    private toView(value: string, characters: number = 700, ellipsis: string = '...'): string {
        if (!value) return value;

        // Check if the value is worth truncating
        if (value.length <= characters + ellipsis.length) return value;

        return `${value.substring(0, characters)}${ellipsis}`;
    }
}
