import moment from 'moment-timezone'

export class DateFormatValueConverter {
    private toView(aDate: string | Date | moment.Moment, format: string = 'D MMM HH:mm'): string {
        let date;
        if(aDate === undefined || aDate === null) {
            return '';
        }
        if (typeof (aDate) === 'string')
            date = Date.parse(aDate);
        else if (typeof (aDate) === 'number') {
            // Find out if number input is in milliseconds (13 numbers) or seconds (10 numbers)
            if ((aDate as number).toString().length === 10)
                aDate = aDate * 1000 as any;

            date = new Date(aDate as number | string);
        } else {
            date = aDate;
        }

        return moment(date).format(format);
    }
}
