export class ChannelValueConverter {
    private toView(channel: string): string {
        switch (channel) {
            case 'Network Threat':
                return 'Network Threat';
            case 'Universal Threat Management':
                return 'UTM Threat';
            case 'Endpoint Detection and Response':
                return 'EDR Threat';
            default:
                return channel;
        }
    }
}
