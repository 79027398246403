export class StatusTextColorValueConverter {
    private toView(name: string): string {
        switch (name.toLowerCase()) {
            case 'pending':
                return 'text-orange';
            case 'ongoing':
                return 'text-orange-light';
            default:
                return 'text-grey';
        }
    }
}