import { InvestigationStatus } from 'services/cyber-api';

export class InvestigationStatusValueConverter {
    private toView(status: InvestigationStatus): string {
        if (!status) return status;

        switch (status.toString()) {
            case InvestigationStatus.Requested:
            case 'Requested':
                return 'Requested';
            case InvestigationStatus.Inprogress:
            case 'InProgress':
                return 'In Progress';
            case InvestigationStatus.Completed:
            case 'Completed':
                return 'Report Ready';
            case InvestigationStatus.Cancelled:
            case 'Cancelled':
                return 'Cancelled';
            default:
                return 'Unknown';
        }
    }
}
